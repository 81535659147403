
import { defineComponent, onMounted, ref, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import moment, { Moment } from 'moment';
import AddProgram from '../components/AddProgram.vue';
import Sortable from 'sortablejs';
import deepClone from '@/utils/deepClone';
import { Program, ReleaseBatchProps } from './types';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import {
  createReleaseBatch,
  confirmReleaseBatch,
  getProgramHomePageData,
  getReleaseBatchDetail,
  pdfPreview,
  saveReleaseBatch,
  sendReleaseBatch,
  sendReleaseEmail,
  getReleaseAddList,
  generateSml
} from '@/API/approve';
import { message, Modal } from 'ant-design-vue';
import blobDownload from '@/utils/blobDownload';
import SalesMeasureDrawer from '../components/SalesMeasureDrawer.vue';

type Key = ColumnProps['key'];
const columns = [
  {
    title: 'Program Code',
    dataIndex: 'programCode',
    key: 'programCode',
    width: 180,
    ellipsis: true
  },
  {
    title: 'Program Name',
    dataIndex: 'programName',
    key: 'programName',
    slots: { customRender: 'programName' },
    width: 100,
    ellipsis: true
  },
  {
    title: 'All Dealer Network',
    dataIndex: 'isAllDealersVisible',
    key: 'isAllDealersVisible',
    slots: { customRender: 'isAllDealersVisible' },
    width: 140,
    align: 'center'
  },
  {
    title: 'Program From Date',
    dataIndex: 'fromDate',
    key: 'programFromDate',
    slots: { customRender: 'programFromDate' },
    width: 180
  },
  {
    title: 'Program To Date',
    dataIndex: 'toDate',
    key: 'programToDate',
    slots: { customRender: 'programToDate' },
    width: 180
  },
  {
    title: 'Reset From_Date',
    dataIndex: 'resetFromDate',
    key: 'resetFromDate',
    slots: { customRender: 'resetFromDate' },
    width: 180
  },
  {
    title: 'Row Set',
    dataIndex: 'rowSet',
    key: 'rowSet',
    slots: { customRender: 'rowSet' },
    width: 100,
    align: 'center'
  }
];

export default defineComponent({
  name: 'BatchReleaseSetting',
  props: {
    releaseId: {
      type: String
    },
    status: {
      type: String
    }
  },
  components: {
    AddProgram,
    SalesMeasureDrawer
  },
  setup(props) {
    const { replace, push } = useRouter();

    const { state, commit } = useStore();

    const visibleAddProgramModal = ref<boolean>(false);
    const allDealerVisiable = ref<boolean>(false);

    //release batch 数据，原始数据用于显示
    const releaseBatch = reactive<ReleaseBatchProps>({
      id: '',
      bu: '',
      manualRelease: false,
      programIds: '',
      programs: [],
      releaseAt: '',
      releaseBatchName: '',
      releaseTo: '',
      status: '',
      isAllDealersVisible: false
    });

    //release batch 备份数据，用于排序，最后使用的数据
    const releaseBatchBack = reactive<ReleaseBatchProps>({
      id: '',
      bu: '',
      manualRelease: false,
      programIds: '',
      programs: [],
      releaseAt: '',
      releaseBatchName: '',
      releaseTo: '',
      status: '',
      isAllDealersVisible: false
    });

    //页面绑定的字段，save的时候使用
    const validateData = reactive<ReleaseBatchProps>({
      id: '',
      bu: '',
      releaseBatchName: '',
      releaseAt: '',
      manualRelease: false,
      releaseTo: '',
      programIds: '',
      programs: [],
      status: '',
      isAllDealersVisible: false
    });

    const rowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: Program[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: Program[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: []
    });

    const programList = ref<{ programId: string; programCode: string; programName: string }[]>([]);

    const resetTableSelectData = (): void => {
      rowSelection.selectedRowKeys = [];
      rowSelection.selectedRowData = [];
    };
    const reStatus = ref<string>();
    const smlStatus = ref(-1);
    const findReleaseBatchDataByReleaseId = (): void => {
      getReleaseBatchDetail({
        params: { id: props.releaseId as string }
      }).then((data: any) => {
        Object.assign(releaseBatch, data);
        Object.assign(releaseBatchBack, data);
        Object.assign(validateData, data);
        reStatus.value = data.status;
        smlStatus.value = data.smlStatus;
      });
    };

    const removeData = ref<any[]>([]);
    const findReleaseBatchDataByProgramId = (): void => {
      const params = {
        id: props.releaseId as string,
        programs: state.approve.programIds
      };
      confirmReleaseBatch(params).then((data) => {
        Object.assign(releaseBatch, data);
        Object.assign(releaseBatchBack, data);
      });
    };

    const handlerAddClick = (): void => {
      // 新增接口
      const params = {
        programs: releaseBatch.programs.map((item) => item.programId),
        bu: releaseBatchBack.buArr?.join()
      };
      getReleaseAddList(params).then((data) => {
        programList.value = data.concat(removeData.value);
        const idArr = programList.value.map((item) => item.programId);
        programList.value = programList.value.filter((item, index) => {
          return idArr.indexOf(item.programId) === index;
        });
        visibleAddProgramModal.value = true;
      });
    };

    const handlerRemoveClick = (): void => {
      const programs = releaseBatch.programs
        .filter((item, index) => !rowSelection.selectedRowKeys.includes(index))
        .map((item) => ({ programId: item.programId }));
      if (programs.length <= 0) {
        message.error('最少保留一条program');
        return;
      }
      removeData.value = removeData.value.concat(
        releaseBatch.programs.filter((item, index) => rowSelection.selectedRowKeys.includes(index))
      );
      releaseBatch.programs = releaseBatch.programs.filter(
        (item, index) => !rowSelection.selectedRowKeys.includes(index)
      );
      releaseBatchBack.programs = releaseBatchBack.programs.filter(
        (item, index) => !rowSelection.selectedRowKeys.includes(index)
      );
      resetTableSelectData();
      // commit('approve/updateProgramIds', programs);
      // findReleaseBatchDataByProgramId();
    };

    const handlerConfirmClick = (programs: { programId: string }[]): void => {
      const data = releaseBatch.programs.map((item) => ({
        programId: item.programId
      }));
      // commit('approve/updateProgramIds', [...state.approve.programIds, ...programs]);
      removeData.value = removeData.value.filter((item) =>
        programs.some((program) => program.programId !== item.programId)
      );
      commit('approve/updateProgramIds', [...data, ...programs]);
      findReleaseBatchDataByProgramId();
    };

    const handleAllDealerChange = (record: any): void => {
      console.log(record.isAllDealersVisible);
      if (record.isAllDealersVisible) {
        allDealerVisiable.value = true;
      }
      return (releaseBatchBack.isAllDealersVisible = record.isAllDealersVisible);
    };

    const handlerSaveClick = async (record: any) => {
      if (!validateData.releaseBatchName) {
        message.error('请输入releaseBatchName');
        return;
      }
      if (!validateData.releaseAt && !validateData.manualRelease) {
        message.error('请选择releaseAt或者manualRelease');
        return;
      }
      if (!validateData.releaseTo) {
        message.error('请选择releaseTo');
        return;
      }

      const programs = releaseBatchBack.programs.map((item) => ({
        programId: item.programId,
        dummy: item.dummy ? item.dummy : false,
        resetFromDate: item.resetFromDate,
        isAllDealersVisible: item.isAllDealersVisible
      }));
      const body = Object.assign(deepClone<ReleaseBatchProps>(validateData), {
        programs,
        id: releaseBatchBack.id,
        bu: releaseBatchBack.bu
      });
      saveReleaseBatch(body).then((data) => {
        Object.assign(releaseBatchBack, data);
        releaseBatch.id = data.id;
        //为了实现记录batchId，防止刷新丢状态
        replace({
          path: '/approval/sales/releaseSetting',
          query: { releaseId: data.id }
        });
        smlStatus.value = data.smlStatus as number;
        message.success('save successfully');
        if (props.releaseId) {
          findReleaseBatchDataByReleaseId();
        }
      });
    };

    const handlerPdfPreviewClick = (): void => {
      if (!releaseBatchBack.id) {
        message.error('请先save');
        return;
      }
      pdfPreview(releaseBatchBack.id as string).then((data) => {
        const href = window.URL.createObjectURL(data);
        window.open(href);
        setTimeout(() => {
          window.URL.revokeObjectURL(href);
        }, 1500);
      });
    };

    const send = (): void => {
      if (!releaseBatchBack.id || !props.releaseId) {
        message.error('请先保存后send');
        return;
      }
      //console.log(selectedRows.isAllDealersVisible);
      const programs = releaseBatchBack.programs.map((item) => ({
        programId: item.programId,
        dummy: item.dummy ? item.dummy : false,
        resetFromDate: item.resetFromDate,
        isAllDealersVisible: item.isAllDealersVisible
      }));
      sendReleaseBatch({
        programs,
        id: (releaseBatchBack.id as string) || props.releaseId
      }).then((data) => {
        if (data.id) {
          const params = { batchId: data.id };
          sendReleaseEmail(params).then(() => {
            message.success('send successfully');
            push({
              path: '/approval/sales/releasePackageHome'
            });
          });
        }
      });
    };

    const checkGenerateVisible = ref(false);
    const handlerSendClick = (selectedRows: any) => {
      if (smlStatus.value === 0) {
        checkGenerateVisible.value = true;
      } else {
        send();
      }
    };
    const checkCancel = () => {
      checkGenerateVisible.value = false;
    };

    const handlerDateChange = (): void => {
      validateData.manualRelease = false;
    };

    const handlerCheckboxChange = (): void => {
      validateData.releaseAt = '';
    };

    const closeDealerModal = () => {
      allDealerVisiable.value = false;
    };

    const handlerReleaseToChange = (checkedValue: string[]): void => {
      validateData.releaseTo = checkedValue.join('&');
    };

    const range = (date: Moment, start: number, end: number, type: string): number[] => {
      const result: number[] = [];
      if (date > moment()) {
        return result;
      }
      for (let i = start; i < end; i++) {
        if (type == 'HH') {
          i < Number(moment().format('HH')) && result.push(i);
        }
        if (type == 'mm') {
          i <= Number(moment().format('mm')) && result.push(i);
        }
      }

      return result;
    };

    const disabledDate = (current: Moment, fromTime: Moment) => {
      return current < moment(fromTime) || current > moment(fromTime).endOf('month');
    };

    const disabledDateReleaseAt = (current: Moment) => {
      return current && current < moment().startOf('days');
    };

    const disabledDateTime = (date: Moment) => {
      const disabled: number[] = [];
      for (let i = 0; i < 60; i++) {
        disabled.push(i);
      }
      return {
        // disabledHours: () => range(date, 0, 23, 'HH'),
        // disabledMinutes: () => disabled,
        // disabledSeconds: () => disabled,
      };
    };

    const optionTime = () => {
      const disabled: number[] = [];
      for (let i = 0; i < 60; i++) {
        disabled.push(i);
      }
      return {
        hideDisabledOptions: true
      };
    };

    const handleWordingDownload = () => {
      if (!releaseBatchBack.id) {
        message.error('Please select!');
        return;
      }
      const params = {
        batchId: releaseBatchBack.id
      };
      blobDownload({
        url: '/pgapi/program/wording/download_release_batch',
        params: params
      });
    };

    const hanledPdfCoverPageClick = () => {
      sessionStorage.setItem(
        'batchIdParams',
        JSON.stringify({
          batchId: releaseBatchBack.id,
          coverCn: '',
          coverEn: ''
        })
      );
      window.open(`${window.location.origin}/#/coverpagedit`);
    };

    const initSortable = (): void => {
      const antTableTbodyEl = document.querySelector('.ant-table-tbody');
      new Sortable(antTableTbodyEl as HTMLElement, {
        handle: '.trigger',
        animation: 500,
        fallbackOnBody: true,
        dragoverBubble: true,
        onUpdate: function (evt) {
          const oldIndex = evt.oldIndex as number;
          const newIndex = evt.newIndex as number;
          const _releaseBatchBack = deepClone(releaseBatchBack);
          const item = JSON.parse(JSON.stringify(releaseBatchBack.programs[oldIndex]));
          _releaseBatchBack.programs.splice(evt.oldIndex as number, 1);
          _releaseBatchBack.programs.splice(newIndex, 0, item);
          Object.assign(releaseBatchBack, _releaseBatchBack);
          // Young drag & drop damaged dom,
          // releaseBatch.programs = [];
          // setTimeout(() => {
          //     releaseBatch.programs = _releaseBatchBack.programs;
          // })
        }
      });
    };

    // 跳转到release页面
    const toReleasePage = (type: string) => {
      if (!props.releaseId) {
        message.error('Please save the batch first.');
        return;
      }
      if (props.status === 'Discard') {
        message.error('ReleaseBatch NOT EXISTS');
        return;
      }
      push({
        name: 'Release',
        query: {
          type,
          id: props.releaseId as string,
          buArr: releaseBatch.buArr as [],
          batch: validateData.releaseBatchName,
          status: reStatus.value as string
        }
      });
    };

    const visibleSalesMeasureDrawer = ref(false);
    const handleViewClick = () => {
      visibleSalesMeasureDrawer.value = true;
    };
    const getSmlGenerated = () => {
      generateSml({ batchId: releaseBatchBack.id }).then((res: any) => {
        smlStatus.value = 1;
        handleViewClick();
      });
    };

    onMounted(() => {
      if (props.releaseId) {
        findReleaseBatchDataByReleaseId();
      } else {
        // findReleaseBatchDataByProgramId()
        createReleaseBatch({ programs: state.approve.programIds }).then((data) => {
          Object.assign(releaseBatch, data);
          Object.assign(releaseBatchBack, data);
          reStatus.value = data.status as string;
        });
      }
      initSortable();
    });
    return {
      columns,
      // dataSource,
      visibleAddProgramModal,
      rowSelection,

      //事件处理
      handlerAddClick,
      handlerRemoveClick,
      handlerConfirmClick,
      handlerSaveClick,
      handlerPdfPreviewClick,
      handlerSendClick,
      checkGenerateVisible,
      send,
      checkCancel,
      handlerDateChange,
      handlerCheckboxChange,
      handlerReleaseToChange,
      handleAllDealerChange,
      allDealerVisiable,
      closeDealerModal,
      //moment
      moment,

      ...toRefs(releaseBatch),
      validateData,

      //add program
      programList,

      //禁止时间
      disabledDate,
      disabledDateTime,
      optionTime,
      disabledDateReleaseAt,
      handleWordingDownload,
      hanledPdfCoverPageClick,
      releaseBatchBack,
      toReleasePage,
      reStatus,
      smlStatus,
      visibleSalesMeasureDrawer,
      handleViewClick,
      getSmlGenerated
    };
  }
});
