
    import { defineComponent, PropType, reactive } from 'vue';
    import { ColumnProps } from 'ant-design-vue/es/table/interface';

    type Key = ColumnProps['key'];

    interface Program {
        programCode: string;
        programName: string;
        programId: string;
    }

    const columns = [
        {
            title: 'Program Code',
            dataIndex: 'programCode',
            key: 'code',
            width: 220,
            ellipsis: true

        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            key: 'name',
            width: 240,
            ellipsis: true
        }
    ]

    export default defineComponent({
        name: "AddProgram",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            dataSource: {
                required: true,
                type: Array as PropType<Program[]>,
                default: () => {
                    return []
                }
            }
        },
        emits: ['update:visible', 'confirm'],
        setup(props, content) {

            const rowSelection = reactive<{ selectedRowKeys: Key[]; onChange: Function }>({
                onChange: (selectedRowKeys: Key[]) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                },
                selectedRowKeys: []
            })

            const handlerConfirm = (): void => {
                const program = props.dataSource.filter((item: Program, index: number) => rowSelection.selectedRowKeys.includes(index)).map((item: Program) => ({programId: item.programId }));
                content.emit('confirm', program);
                content.emit('update:visible', false);
                rowSelection.selectedRowKeys = [];
            }

            return {
                columns,
                rowSelection,
                handlerConfirm
            }
        }
    })
