
import { computed, defineComponent, ref, watch } from 'vue';
import { message } from 'ant-design-vue';
import { deleteSml, viewSml } from '@/API/approve';
import axios from 'axios';
import { CheckCircleTwoTone } from '@ant-design/icons-vue';
export default defineComponent({
    components: {
        CheckCircleTwoTone,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true,
        },
        batchId: {
            type: String,
            default: '',
            required: true,
        },
    },
    emits: ['update:visible', 'delete-sml'],
    setup(props, { emit }) {
        const dataSource = ref<any[]>([]);
        const columns = [
            {
                title: 'File Name',
                slots: {
                    customRender: 'FileName',
                },
                width: 300,
                key: 'fileName',
            },
            {
                title: 'Uploaded File Name',
                // dataIndex: 'replacedSmlName',
                slots: {
                    customRender: 'UploadedFileName',
                },
                width: 300,
                key: 'fileName',
            },
            {
                title: 'Operation',
                width: 150,
                slots: {
                    customRender: 'Operation',
                },
            },
        ];
        const cancel = () => {
            emit('update:visible');
        };

        const isUploadByUser = computed(() => {
            if (dataSource.value[0].replacedSmlName) return true;
            return false;
        });
        const uploadRequest = (source: any) => {
            const params = {
                batchId: props.batchId,
            };
            const url = '/pgapi/program/release/uploadSml';
            const formData = new FormData();
            formData.append('file', source.file);
            axios({
                method: 'post',
                data: formData,
                url,
                params,
            }).then((res: any) => {
                message.success('Upload success!');
                dataSource.value = [res.data.data];
            });
        };

        const getViewSml = () => {
            viewSml({ batchId: props.batchId }).then((res: any) => {
                if (res !== null) {
                    dataSource.value = [res];
                } else {
                    dataSource.value = [];
                }
            });
        };

        const deleteCurrentRow = () => {
            deleteSml({
                batchId: props.batchId,
            }).then(() => {
                message.success('Delete Success!');
                getViewSml();
                emit('delete-sml', 0);
            });
        };

        const download = (name: string) => {
            viewSml({ batchId: props.batchId }).then((res) => {
                const downloadElement = document.createElement('a');
                downloadElement.href = res.originalSmlUrl;
                downloadElement.download = name;
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement);
            });
        };

        watch(
            () => props.visible,
            (newVal) => {
                if (newVal) {
                    getViewSml();
                }
            }
        );

        return {
            dataSource,
            columns,
            cancel,
            deleteCurrentRow,
            uploadRequest,
            download,
            isUploadByUser,
        };
    },
});
